$.fn.datepicker.dates['mn'] = {
    days: ["Ням", "Даваа", "Мягмар", "Лхагва", "Пүрэв", "Баасан", "Бямба", "Ням"],
    daysShort: ["Ням", "Дав", "Мяг", "Лха", "Пүр", "Баа", "Бям", "Ням"],
    daysMin: ["Ня", "Да", "Мя", "Лх", "Пү", "Ба", "Бя", "Ня"],
    months: ["1-р сар", "2-р сар", "3-р сар", "4-р сар", "5-р сар", "6-р сар", "7-р сар", "8-р сар", "9-р сар", "10-р сар", "11-р сар", "12-р сар"],
    monthsShort: ["1сар", "2сар", "3сар", "4сар", "5сар", "6сар", "7сар", "8сар", "9сар", "10сар", "11сар", "12сар"],
    today: "Өнөөдөр"
};
$.fn.datepicker.defaults.format = "yyyy-mm-dd";
$.fn.datepicker.defaults.language = "mn";

function getDecimalPay(numval) {
    numval = Math.floor(numval);
    numval = numval + "";
    var cde = 1, vv = "";
    for (var i = 0; i < numval.length; i++) {
        if (numval.charAt(i) != ",") vv += numval.charAt(i);
    }
    numval = vv;
    vv = "";

    for (i = numval.length - 1; i >= 0; i--) {
        if (i != 0 && cde == 3) {
            vv += numval.charAt(i) + ",";
            cde = 1
        } else {
            vv += numval.charAt(i);
            cde++;
        }
    }
    numval = "";
    for (var i = vv.length - 1; i >= 0; i--) {
        numval += vv.charAt(i);
    }
    return numval + "₮";
}

function valid_id(id) {
    return id !== undefined && id !== null && id !== ""
}

function select_parent(select) {
    return $(select).parent().parent().hasClass('field_with_errors') ? $(select).parent().parent().parent().parent() : $(select).parent().parent().parent();
}

function readURL(input, div) {
    if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
            div.html("<img class='preview-img' src='" + e.target.result + "'>");
        };
        reader.readAsDataURL(input.files[0]);
    }
}

function reset_excel_download_btn() {
    $('button#download-excel').removeAttr('disabled');
}

// $(window).on('load', function () {
$(document).ready(function () {
    $(document).on('click', '[data-toggle="lightbox"]', function (event) {
        event.preventDefault();
        $(this).ekkoLightbox({
            alwaysShowClose: true
        });
    });
//     $('.select2').select2({
//         theme: 'bootstrap4'
//     });
//     $('.selectpicker').selectpicker('refresh');
//     $('[data-mask]').inputmask('yyyy-mm-dd', {'im-insert': false});
//
//     // setTimeout($('.select2-search__field').attr("name", 'select2-search__field'), 200);
//
//     $('.preview').on('click', function () {
//         $(this).parent().find('input').click();
//     });
//     $('input.image-file').on('change', function () {
//         readURL(this, $(this).parent().parent().find('.preview:first'));
//     });
//
//     $('input.decimalPay').keydown(function (e) {
//         var key = e.charCode || e.keyCode || 0;
//         // allow backspace, tab, delete, arrows, numbers and keypad numbers ONLY
//         return (
//             key === 8 ||
//             key === 9 ||
//             key === 46 ||
//             (key >= 37 && key <= 40) ||
//             (key >= 48 && key  <= 57) ||
//             (key >= 96 && key <= 105));
//     }).keyup(function (e) {
//         if ($(this).val().length > 0) {
//             $(this).val(getDecimalPay($(this).val()));
//         }
//     }).each(function () {
//         if ($(this).val().length > 0) {
//             $(this).val(getDecimalPay($(this).val()));
//         }
//     }).onpaste = function (e) {
//         e.preventDefault();
//     };
//     $('button#download-excel').on('click', function () {
//         $(this).attr('disabled', 'disabled');
//         var f = $(this).parent().parent().parent();
//         var act = f.attr('action');
//         f.attr('action', act + '.xlsx');
//         f.submit();
//         f.attr('action', act);
//         setTimeout(reset_excel_download_btn, 3000);
//     });

});