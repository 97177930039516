// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
// require("admin-lte/plugins/jquery/jquery.min");
// import 'bootstrap';
require("jquery");
require("admin-lte/plugins/bootstrap/js/bootstrap.bundle.min");
require('admin-lte/plugins/select2/js/select2.full.min');
require('moment/dist/moment');
require('admin-lte/plugins/inputmask/jquery.inputmask.js');

require('admin-lte');
require("@nathanvda/cocoon");
require('admin-lte/plugins/ekko-lightbox/ekko-lightbox.min');
require('bootstrap-daterangepicker/daterangepicker');
require('bootstrap-datepicker/dist/js/bootstrap-datepicker');
require('bootstrap-select/dist/js/bootstrap-select.min');
import "../stylesheets/application.scss";
import "@fortawesome/fontawesome-free/js/all";
import "@hotwired/turbo-rails"
import "../controllers"
import * as bootstrap from "bootstrap"
window.bootstrap = bootstrap
window.moment = moment;
require("packs/common");
require("@googlemaps/markerclusterer");

global.$ = require('jquery');

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
